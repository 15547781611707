body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #D7F6FF;
}
.has-navbar {
  padding-top: 100px; 
}

.navbar {
  background-color: #052F46;
  color: #D7F6FF;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 1rem;
}

.navbar-links a {
  color: #D7F6FF;
  text-decoration: none;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  
}

.hero-section {
  text-align: center;
  margin-bottom: 4rem;
}

.hero-section h1 {
  color: #463931;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-section p {
  color: #052F46;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-button {
  background-color: #C6623A;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.features {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.feature-card {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
  flex: 1;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  color: #463931;
  margin-bottom: 0.5rem;
}

.feature-card p {
  color: #052F46;
}

@media (max-width: 768px) {
  .features {
    flex-direction: column;
  }
}

.signup-container, .login-container {
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.signup-container h2, .login-container h2 {
  text-align: center;
  color: #463931;
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #052F46;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #C6623A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #A54E2E;
}

.error {
  color: rgb(98, 3, 3);
  text-align: center;
  margin-bottom: 10px;
}

/* Updated styles for Dashboard */
.dashboard-container {
  display: flex;
  height: 100vh;
}

.dashboard-sidebar {
  transition: width 0.3s;
  overflow: hidden;
}

.dashboard-sidebar-expanded {
  width: 240px;
}

.dashboard-sidebar-collapsed {
  width: 60px;
}

.dashboard-content {
  flex-grow: 1;
  padding: 2rem;
  background-color: #f5f5f5;
  overflow-y: auto;
}

.dashboard-nav-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.dashboard-nav-item:hover {
  opacity: 0.8;
}

.dashboard-sidebar-toggle {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.dashboard-sidebar-toggle button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.dashboard-sidebar-toggle button:hover {
  opacity: 0.8;
}

/* Common Styles for Authentication Containers */
.auth-container {
  width: 400px;
  margin: 50px auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: left;
}

.auth-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #052F46;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 10px 0;
  background-color: #052F46;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #041a30;
}

.error {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}

.success {
  color: green;
  margin-bottom: 15px;
  text-align: center;
}

.auth-container p {
  text-align: center;
  margin-top: 15px;
}

.auth-container a {
  color: #052F46;
  text-decoration: none;
}

.auth-container a:hover {
  text-decoration: underline;
}



/* Responsive Design */
@media (max-width: 500px) {
  .auth-container {
      width: 90%;
      padding: 20px;
  }
}